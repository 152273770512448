<template>
  <b-row>
    <b-col cols="12">
      <h2>Marketplace</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <!-- <template slot="filters"  >
          <b-col cols="3" class="my-3">
            <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-add-sales-admin
            >
            Añadir nuevo producto
            </b-button>
          </b-col>
        </template> -->
        <template slot-scope="{ item }" slot="image">
          <!-- <img
            v-if="item.image && item.image.path"
            :src="item.image.path"
            alt="Imagen del producto"
            style="max-width: 50px; max-height: 50px"
          /> -->
          <div
            v-if="item.image && item.image.path"
            class="hp-cursor-pointer border hp-border-color-dark-80"
            style="border-radius: 15px; width: fit-content; margin-inline: auto;"
          >
            <div
              :class="`overflow-hidden m-4 d-flex${item.avatarBg}`"
              style="
                min-width: 60px;
                max-width: 60px;
                height: auto;
                border-radius: 15px;
              "
            >
              <img
                :src="item.image.path"
                alt="Imagen del producto"
                height="100%"
              />
            </div>
          </div>
          <span v-else>Sin imagen</span>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons" style="display: contents;">
            <span
              title="ver"
              @click="showUser(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <!-- <span title="editar" @click="editUser(  item.uuid  )"> <i class="iconly-Light-Edit mr-10"></i> </span> -->
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este producto?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create sale -->
    <b-modal
      size="xl"
      id="modal-add-sales-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-sales-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="createSales.title"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre de contacto">
              <b-form-input
                v-model="createSales.contact_name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Teléfono de contacto">
              <b-form-input
                v-model="createSales.contact_phone"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-input
                v-model="createSales.image"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-file
                @change="uploadFile"
                placeholder="Seleccione una imagen"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="create_sale">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-sales-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- View sale detail -->
    <b-modal
      id="modal-show-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle producto</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="createSales.title"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Nombre de contacto">
              <b-form-input
                v-model="createSales.contact_name"
                type="email"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono de contacto">
              <b-form-input
                v-model="createSales.contact_phone"
                type="email"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Contenido">
              <b-form-input
                v-model="createSales.content"
                type="email"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Imagen">
              <img v-bind:src="createSales.image" />
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
  BAvatar,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import buildingsAdmin from "@/logic/functions/buildingsAdmin";
import sales from "@/logic/functions/sales";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    BAvatar,
  },
  data() {
    return {
      fields: [
        { key: "title", label: "Título" },
        {
          key: "image",
          label: "Imagen",
          formatter: (value) => {
            return value && value.path ? value.path : "Sin imagen";
          },
        },
        { key: "contact_phone", label: "Teléfono de contacto" },
        { key: "contact_name", label: "Nombre de contacto" },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      fileUpload: {},
      createSales: {},
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    formValidate() {
      let stateForm = false;

      if (
        this.createSales.title &&
        this.createSales.contact_name &&
        this.createSales.contact_phone &&
        this.createSales.image
      ) {
        stateForm = true;
      }

      return stateForm;
    },
    showUser(uuid) {
      const item = this.items.find((item) => item.uuid === uuid);
      if (item) {
        this.createSales = {
          ...item,
          image: item.image && item.image.path ? item.image.path : "",
        };
        this.$bvModal.show("modal-show-admin");
      }
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteUser(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deleteUser(uuid) {
      let items = this.items;
      sales
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Producto eliminado");
        })
        .catch((error) => {});
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.documents(page);
    },
    async create_sale() {
      if (this.formValidate()) {
        const query = new URLSearchParams({
          contact_name: this.createSales.contact_name,
          contact_phone: this.createSales.contact_phone,
          title: this.createSales.title,
          comment: "images",
        }).toString();

        const file = new FormData();
        file.append("file", this.fileUpload.file);

        try {
          await sales.create(file, query);
          await this.list(); // Actualiza la lista después de crear
          this.$bvModal.hide("modal-add-sales-admin");
          this.resetForm(); // Asegúrate de tener este método
          sweet.ToastMsg("", "success", "Producto creado");
        } catch (error) {
          console.error("Error al crear el producto:", error);
          sweet.ToastMsg("", "error", "Producto no creado");
        }
      } else {
        sweet.ToastMsg("", "error", "Campos no llenados");
      }
    },

    uploadFile(event) {
      this.fileUpload.file = event.target.files[0];
    },
    async list(page = 0) {
      try {
        const response = await sales.list({
          params: {
            limit: this.totalRowsPerpage,
            page: page,
          },
        });
        this.items = response.data.data;
        this.totalRows = response.data.total; // Asumiendo que la API devuelve el total
      } catch (error) {
        console.error("Error al obtener la lista de productos:", error);
        sweet.ToastMsg("", "error", "Error al cargar los productos");
      }
    },
  },
  beforeMount() {},
  mounted() {
    this.list();
  },
};
</script>
