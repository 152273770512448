//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Sales {
 
  list(sales_data){
    return http.get( api.sales.get.endpoint, sales_data);
  }

  list_admin(sales_data){
    return http.get( api.sales.get_admin.endpoint, sales_data );
  }

  create(file,params){
    return http.post( api.sales.create.endpoint+params, file ,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } );
  }
  
  update(sales_data){
    return http.put( api.sales.update.endpoint, sales_data );
  }

  delete(sales_data){
    return http.delete( api.sales.delete.endpoint+sales_data );
  }

}

export default new Sales();